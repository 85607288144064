$background: #020203;
$light-dark: #232226;
$grey: rgb(207, 230, 234);
$title-color: #fff;
$text-color: #bababa;

body {
  font-family: 'Work Sans', sans-serif;
  background-color: $background;
  color: $text-color;
}

h1 {
  color: $title-color;
  font-weight: bold;
  text-transform: uppercase;
}

.title {
  font-family: 'Questrial';
  font-weight: bold;
  color: $title-color;
}

.gradient-bg {
  background: linear-gradient(to right bottom, $background, $light-dark);
  color: $text-color;
}

.light-dark-bg {
  background-color: $text-color;
  color: $title-color;
}

.grey-bg {
  background-color: $grey;
  color: $light-dark;
}

button.title {
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
}

.headline-complex {
  width: calc(100% - 100px);
  height: calc(100vh - 300px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;

  .headline-text {
    width: 50%;
  }

  .illustration {
    height: 90%;
    margin: auto;
  }
}

button.primary {
  font-family: 'Questrial';
  color: $background;
  font-weight: bold;
  border-radius: 10px;
  background: $grey;
  padding: 1rem;
  width: 15rem;

  &:hover {
    box-shadow: 0px 0px 30px darken($color: $grey, $amount: 50%);
  }
}

.input {
  outline: none;
  padding-left: 12px;
  height: 3rem;
  border-radius: 12px;
  background: #232226;
  box-shadow: inset 18px 18px 37px #0e0e0f, inset -18px -18px 37px #38363d;
  border: 1px solid $light-dark;
}

@media (max-width: 768px) {
  .headline-complex {
    width: calc(100% - 12px);
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;

    .headline-text {
      width: calc(100% - 12px);
      margin: auto;
    }

    .illustration {
      width: calc(100% - 12px);
      margin: auto;
      margin-top: 24px;
    }
  }
}
